var navbarNav = document.getElementById('navbarTogglerMenu01');
var navbarNavToggler = document.getElementById('navbarToggler')

var navbarNavItems = navbarNav.querySelectorAll('.menu-item a');

var navbarNavBsCollapse = new bootstrap.Collapse(navbarNav, {
	toggle: false,
});

navbarNavToggler.addEventListener('click', function(evt) {
	if ( navbarNav.classList.contains('show') ) {
		navbarNavBsCollapse.hide();
	} else {
		navbarNavBsCollapse.show();
	}
});

navbarNavItems.forEach( item => {
	item.addEventListener( 'click', event => {
		if ( navbarNav.classList.contains('show') ) {
			navbarNavBsCollapse.hide();
		}
	});
})
