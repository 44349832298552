(function($) {

$(document).ready(function() {
	const current = window.location.pathname;

	const landingPaginationExists = !!document.getElementById('LandingPostsPagination');

	if ( landingPaginationExists ) {
	  const LandingPostsPagination = new Pagination( 'LandingPostsPagination', 1, '.js-posts-container', 'Noticias' );
	} else {
	  return;
	}
	
})

class Pagination {
	htmlElm;
	currentPage;
	maxPages;
	numItemsToShow;
	navElms;
	postsPerPage;
	postsContainer;
	scrollToElmID;

	constructor ( elmID, currentPage, postsContainer, scrollToElmID, numItemsToShow = 4 ) {
		this.htmlElm = document.getElementById( elmID );
		this.currentPage = currentPage;
		this.maxPages = this.htmlElm.dataset.maxPages;
		this.postsPerPage = this.htmlElm.dataset.postsPerPage;

		this.numItemsToShow = numItemsToShow;
		this.navElms = new Array();
		this.postsContainer = document.querySelector( postsContainer );
		this.scrollToElmID = scrollToElmID;

		this.setPagination( this.currentPage );
	}

	setPagination( currentPage ) {
		const middle = Math.floor( this.numItemsToShow / 2 );
		const startOnIndex = currentPage > this.numItemsToShow ? currentPage - middle : 1;
		const loopLimiter = ( middle + startOnIndex ) >= this.maxPages ? this.maxPages : ( this.numItemsToShow + startOnIndex );

		let navElm = document.createElement('nav');
		navElm.classList.add('posts-pagination-pages');
		
		for ( let i = startOnIndex ; i <= loopLimiter; i++  ) {
			let pageIndex = document.createElement('span');

			pageIndex.innerHTML = i;
			pageIndex.classList.add('pages-index');

			if ( i === this.currentPage ) pageIndex.classList.add('current');

			navElm.append(pageIndex);
			this.navElms.push(pageIndex);
		}

		this.htmlElm.replaceChildren(navElm);
		this.watchClick();
	}

	handleActiveClasses ( toPage ) {
		this.navElms.forEach( elm => elm.classList.remove('current') );
		this.navElms[toPage-1].classList.add('current');
	}

	watchClick ( elmClass = 'pages-index' ) {
		const thisClass = this;
		const indexElms = this.htmlElm.querySelectorAll( '.' + elmClass );

		indexElms.forEach( elm => elm.addEventListener('click', (e) => {
			const toPage = parseInt( e.target.innerHTML );

			thisClass.runAjax ( toPage );
			thisClass.handleActiveClasses ( toPage );
		}));
	}

	runAjax ( toPage ) {
		const thisClass = this;
		const data = {
			action: 'pagination',
			paged: toPage,
			posts_per_page: this.postsPerPage,
			container_id: 'Noticias',
		};

		$.ajax({
			type: 'post',
			url: wpAdminAjax.url,
			data: data,
			beforeSend: function () {
			},
			success: function ( response, status ) {
				if ( status === 'success' ) {					
					if ( response ) {
						const sectionContainer = document.getElementById( thisClass.scrollToElmID );

						thisClass.postsContainer.innerHTML = response;
						sectionContainer.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start', duration: '2000' });
					}

				} else {
					console.log( 'There was some kind of error. Try again.' );
				}
			}
		});
	}	
}

})(jQuery);